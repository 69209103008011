import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import { NavBar } from '../components/common';

import './legalPageTemplateStyles.styl';

export default function LegalPageTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark; // TODO: page title
  return (
    <Layout>
      <NavBar withLogo withBasket={false} withBurger={false} />
      <div className="legals" dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;
